<template>
  <BaseModalSmall
    @close="close"
    v-show="show"
    :show="show"
    v-bind:isValidated="validateForm"
    v-bind:loading="showProgress"
    @confirm="save"
  >
    <template v-slot:header>{{ title }}</template>
    <template v-slot:body>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.make"
              type="text"
              class="input-standard-grey validation"
              placeholder="Make"
              required
              id="make"
            />
            <label for="make">Make</label>
            <ErrorPop
              v-show="errors.make"
              :message="errors.make"
              :displayClose="true"
              @clearError="errors.make = null"
            ></ErrorPop>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.model"
              type="text"
              class="input-standard-grey validation"
              placeholder="Model"
              required
              id="post_town"
            />
            <label for="model">Model</label>
            <ErrorPop
              v-show="errors.model"
              :message="errors.model"
              :displayClose="true"
              @clearError="errors.model = null"
            ></ErrorPop>
          </div>
        </div>

        <BeneficiaryAccordian
          :description="'Who is to be the recipient of the gift?'"
          v-bind:name="data.full_name"
          v-on:name="data.full_name = $event"
          v-bind:gender="data.gender"
          v-on:gender="data.gender = $event"
          v-bind:email="data.email"
          v-on:email="data.email = $event"
          v-bind:address="data.address"
          v-on:address="data.address = $event"
          v-bind:phone="data.phone"
          v-on:phone="data.phone = $event"
          v-bind:relationship="data.relationship"
          v-on:relationship="data.relationship = $event"
          v-bind:group="data.beneficiary_group"
          v-on:group="data.beneficiary_group = $event"
          v-bind:person="data.beneficiary"
          v-on:person="data.beneficiary = $event"
          v-bind:manualRelationship="manualRelationship"
          v-on:manualRelationship="manualRelationship = $event"
          :close="show"
          :errors="errors"
          :edit="edit"
          :groupNotRequired="true"
          :shareNotRequired="true"
          :gift="true"
          @showExisting="clearGroup(), clearNew()"
          @showNew="clearExisting(), clearGroup()"
          @showGroup="clearExisting(), clearNew()"
          @clearBeneficiaryError="errors.beneficiary = null"
          @clearFullNameError="errors.full_name = null"
          @clearEmailError="errors.email = null"
          @clearAddressError="errors.address = null"
          @clearPhoneError="errors.phone = null"
        >
        </BeneficiaryAccordian>
      </div>
    </template>
    <template v-slot:button>
      {{ buttonText }}
    </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import BeneficiaryAccordian from './BeneficiaryAccordian'
import ErrorPop from '@/common/ui/ErrorPop'
import { http } from '@/services'

export default {
  name: 'GiftPropertyModal',
  components: {
    BaseModalSmall,
    BeneficiaryAccordian,
    ErrorPop
  },
  props: ['show', 'edit', 'before', 'after'],
  watch: {
    edit: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.id = value.id
          this.data.make = value.make
          this.data.model = value.model
          if (value.beneficiary_group)
            this.data.beneficiary_group = value.beneficiary_group
          if (value.beneficiary) this.data.beneficiary = value.beneficiary.id
        } else {
          this.clearAll()
        }
      }
    },
    before: {
      immediate: true,
      handler(value) {
        if (value) this.data.gift_before_partner = true
      }
    },
    after: {
      immediate: true,
      handler(value) {
        if (value) this.data.gift_after_partner = true
      }
    }
  },
  computed: {
    validateForm() {
      if (this.data.make && this.data.model) {
        if (this.data.beneficiary) return true
        if (this.data.full_name && this.data.gender && this.data.relationship) {
          if (
            this.data.relationship === 'friend' &&
            (this.data.phone || this.data.address || this.data.email)
          )
            return true
          else if (
            this.data.relationship === 'other' &&
            this.manualRelationship
          )
            return true
          else if (
            this.data.relationship !== 'friend' &&
            this.data.relationship !== 'other'
          )
            return true
        }
        if (this.data.beneficiary_group) return true
      }
      return false
    },
    title() {
      if (this.edit) return 'Update Vehicle Gift'
      return 'Add Vehicle Gift'
    },
    buttonText() {
      if (this.edit) return 'Update Gift'
      return 'Add Gift'
    }
  },
  data() {
    return {
      data: {
        id: null,
        model: null,
        make: null,
        beneficiary: null,
        full_name: null,
        address: null,
        email: null,
        phone: null,
        gender: null,
        relationship: '',
        beneficiary_group: '',
        gift_before_partner: false,
        gift_after_partner: false
      },
      errors: {
        make: '',
        model: '',
        beneficiary: '',
        full_name: '',
        address: '',
        email: '',
        phone: ''
      },
      showProgress: false,
      manualRelationship: ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.clearAll()
    },
    save(value) {
      if (this.edit && this.data.id) {
        this.patch()
      } else {
        this.post()
      }
    },
    clearErrors() {
      this.errors.make = ''
      this.errors.model = ''
      this.errors.full_name = ''
      this.errors.address = ''
      this.errors.phone = ''
      this.errors.email = ''
      this.errors.beneficiary = ''
    },
    clearAll() {
      this.data.id = null
      this.data.make = null
      this.data.model = null
      this.clearExisting()
      this.clearNew()
      this.clearGroup()
    },
    clearExisting() {
      this.data.beneficiary = null
    },
    clearNew() {
      this.data.full_name = null
      this.data.address = null
      this.data.email = null
      this.data.phone = null
      this.data.gender = null
      this.data.relationship = ''
    },
    clearGroup() {
      this.data.beneficiary_group = ''
    },
    post() {
      this.clearErrors()
      if (this.data.relationship === 'other' && this.manualRelationship)
        this.data.relationship = this.manualRelationship
      http
        .post('/wills/api/gift_vehicle', this.data)
        .then((response) => {
          this.$store.commit('giftVehicleAdd', response.data)
          if (this.data.full_name) this.$store.dispatch('peopleFetch')
          this.close()
          this.$notification.success('Vehicle gift created')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    patch() {
      this.clearErrors()
      http
        .patch('/wills/api/gift_vehicle', this.data)
        .then((response) => {
          this.$store.commit('giftVehicleAdd', response.data)
          if (this.data.full_name) this.$store.dispatch('peopleFetch')
          this.close()
          this.$notification.success('Vehicle gift edited')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    errorHandler(e) {
      if (e.response.data.make) {
        this.errors.make = e.response.data.make[0]
      }
      if (e.response.data.model) {
        this.errors.model = e.response.data.model[0]
      }
      if (e.response.data.beneficiary) {
        this.errors.beneficiary = e.response.data.beneficiary[0]
      }
      if (e.response.data.full_name) {
        this.errors.full_name = e.response.data.full_name[0]
      }
      if (e.response.data.phone) {
        this.errors.phone = e.reponse.data.phone[0]
      }
      if (e.response.data.email) {
        this.errors.email = e.reponse.data.email[0]
      }
      if (e.response.data.address) {
        this.errors.address = e.reponse.data.address[0]
      }
    }
  }
}
</script>
