<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <p v-if="description">{{ description }}</p>
    <div class="modal-accordion-wrapper">
      <div
        v-on:click="toggleButtons('existing')"
        class="modal-accordion-head-wrapper"
      >
        <div
          class="modal-accordion-head"
          :class="{ collapsed: !show.existing }"
        >
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/accordion-existing.png"
            alt="existing"
          />
          <h5>Existing Person</h5>
        </div>
      </div>
      <div
        v-on:click="toggleButtons('new')"
        class="modal-accordion-head-wrapper"
      >
        <div class="modal-accordion-head" :class="{ collapsed: !show.new }">
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/accordion-new.png"
            alt="new person"
          />
          <h5>New Person</h5>
        </div>
      </div>
      <div
        v-if="!groupNotRequired"
        v-on:click="toggleButtons('group')"
        class="modal-accordion-head-wrapper"
      >
        <div class="modal-accordion-head" :class="{ collapsed: !show.group }">
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/accordion-group.png"
            alt="group"
          />
          <h5>Group of People</h5>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div v-show="show.existing">
        <div class="input-container">
          <PersonSelect
            :psn="person"
            :required="false"
            :peopleOptionList="peopleOptionList"
            @selected="$emit('person', $event)"
          ></PersonSelect>
          <ErrorPop
            v-show="errors.beneficiary"
            :message="errors.beneficiary"
            :displayClose="true"
            @clearError="$emit('clearBeneficiaryError')"
          ></ErrorPop>
        </div>
      </div>
    </transition>

    <transition name="slide">
      <div v-show="show.new">
        <div class="input-container">
          <input
            v-bind:value="name"
            v-on:input="$emit('name', $event.target.value)"
            type="text"
            class="input-standard-grey validation"
            placeholder="Full Name"
          />
          <label>Full Name</label>
          <ErrorPop
            v-show="errors.full_name"
            :message="errors.full_name"
            :displayClose="true"
            @clearError="$emit('clearFullNameError')"
          ></ErrorPop>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
            <RelationshipSelect
              :rel="relationship"
              :required="name"
              @selected="$emit('relationship', $event)"
            ></RelationshipSelect>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="input-container">
              <div
                v-on:click="$emit('gender', 'Male')"
                class="btn-selector btn-male btn field"
                :class="{
                  'btn--green': gender === 'Male',
                  'btn-unselected': gender !== 'Male'
                }"
              >
                Male
              </div>
              <div
                v-on:click="$emit('gender', 'Female')"
                class="btn-selector btn-female btn field"
                :class="{
                  'btn--green': gender === 'Female',
                  'btn-unselected': gender !== 'Female'
                }"
              >
                Female
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div v-show="showRequiredAdditional" class="friend-text">
              Everybody must be clearly identified in your Will. Because this
              person is not a relation, you must add their home address
              <span>OR</span> their email address:
            </div>
            <transition name="slide">
              <div v-show="showManualRelationship">
                <div class="input-container">
                  <input
                    v-bind:value="manualRelationship"
                    v-on:input="
                      $emit('manualRelationship', $event.target.value)
                    "
                    type="text"
                    class="input-standard-grey validation"
                    placeholder="Relationship"
                    required
                    id="relationshipManual"
                  />
                  <label for="relationshipManual">Relationship</label>
                </div>
              </div>
            </transition>

            <transition name="slide">
              <div v-show="show.additional || showRequiredAdditional">
                <div class="card-body">
                  <div class="input-container">
                    <input
                      v-bind:value="phone"
                      v-on:input="$emit('phone', $event.target.value)"
                      type="text"
                      class="input-standard-grey validation"
                      placeholder="Mobile"
                      :required="showRequiredAdditional && !address && !email"
                      id="mobile"
                    />
                    <label for="mobile">Mobile</label>
                    <ErrorPop
                      v-show="errors.phone"
                      :message="errors.phone"
                      :displayClose="true"
                      @clearError="$emit('clearPhoneError')"
                    ></ErrorPop>
                  </div>
                  <div class="input-container">
                    <input
                      v-bind:value="address"
                      v-on:input="$emit('address', $event.target.value)"
                      type="text"
                      class="input-standard-grey validation"
                      placeholder="Address"
                      :required="showRequiredAdditional && !phone && !email"
                    />
                    <label>Address</label>
                    <ErrorPop
                      v-show="errors.address"
                      :message="errors.address"
                      :displayClose="true"
                      @clearError="$emit('clearAddressError')"
                    ></ErrorPop>
                  </div>
                  <div class="input-container">
                    <input
                      v-bind:value="email"
                      v-on:input="$emit('email', $event.target.value)"
                      type="email"
                      class="input-standard-grey validation"
                      placeholder="Email"
                      :required="showRequiredAdditional && !address && !phone"
                    />
                    <label>Email</label>
                    <ErrorPop
                      v-show="errors.email"
                      :message="errors.email"
                      :displayClose="true"
                      @clearError="$emit('clearEmailError')"
                    ></ErrorPop>
                  </div>
                </div>
              </div>
            </transition>

            <div v-show="!showRequiredAdditional" class="add-dropdown-header">
              <h5>
                <div class="add-dropdown-button">
                  <span v-if="!show.additional" v-on:click="toggleAdditional"
                    >Add contact details (optional)</span
                  >
                  <span v-else
                    ><span v-on:click="toggleAdditional">Close</span></span
                  >
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide">
      <div v-if="!groupNotRequired" v-show="show.group">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-container">
              <GroupSelect
                :grp="group"
                :required="false"
                @selected="$emit('group', $event)"
              ></GroupSelect>
            </div>
          </div>
          <div
            v-if="!shareNotRequired"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="input-container group-amount-buttons">
              <div
                v-on:click="$emit('groupShare', 'each')"
                :class="{
                  'btn--green': groupShare === 'each',
                  'btn-unselected': groupShare !== 'each'
                }"
                class="btn-selector btn-male btn field"
              >
                <span class="group-amount"></span> Each
              </div>
              <div
                v-on:click="$emit('groupShare', 'shared')"
                class="btn-selector btn-female btn field"
                :class="{
                  'btn--green': groupShare === 'shared',
                  'btn-unselected': groupShare !== 'shared'
                }"
              >
                <span class="group-amount"></span> Shared
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RelationshipSelect from '@/common/components/RelationshipSelect'
import GroupSelect from '@/common/components/GroupSelect'
import PersonSelect from '@/common/components/PersonSelect'
import ErrorPop from '@/common/ui/ErrorPop'

export default {
  name: 'BeneficiaryAccordian',
  props: [
    'name',
    'address',
    'email',
    'phone',
    'gender',
    'relationship',
    'description',
    'manualRelationship',
    'groupShare',
    'group',
    'person',
    'close',
    'errors',
    'edit',
    'groupNotRequired',
    'shareNotRequired',
    'gift',
    'peopleOptionList'
  ],
  components: {
    RelationshipSelect,
    GroupSelect,
    PersonSelect,
    ErrorPop
  },
  watch: {
    close: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value === false) this.closeAllSections()
      }
    },
    edit: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && (value.beneficiary || value.distribution_backup_person))
          this.show.existing = true
        else if (
          value &&
          (value.beneficiary_group || value.distribution_backup_group)
        )
          this.show.group = true
        else {
          this.show.existing = false
          this.show.group = false
        }
      }
    }
  },
  data() {
    return {
      show: {
        existing: false,
        new: false,
        group: false,
        additional: false
      }
    }
  },
  computed: {
    showRequiredAdditional() {
      return this.relationship === 'friend'
    },
    showManualRelationship() {
      return this.relationship === 'other'
    }
  },
  methods: {
    closeAllSections() {
      this.show.existing = false
      this.show.new = false
      this.show.group = false
      this.show.additional = false
    },
    toggleAdditional() {
      this.show.additional = !this.show.additional
    },
    toggleButtons(button) {
      if (button === 'group') {
        this.show.group = !this.show.group
        this.show.new = false
        this.show.existing = false
        this.$emit('showGroup')
        console.log('show group')
      }
      if (button === 'new') {
        this.show.new = !this.show.new
        this.show.group = false
        this.show.existing = false
        this.$emit('showNew')
      }
      if (button === 'existing') {
        this.show.existing = !this.show.existing
        this.show.new = false
        this.show.group = false
        this.$emit('showExisting')
      }
    }
  }
}
</script>
