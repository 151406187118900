<template>
  <GiftRowBase
    :img="'https://mylastwill.s3.amazonaws.com/static/img/button-gift-cash.png'"
    :description="description"
    :shared="gift.beneficiary_group_share"
    :beneficiary="gift.beneficiary_group || gift.beneficiary.full_name"
    @editGift="$emit('editCashGift')"
    @deleteGift="$emit('deleteCashGift')"
  >
  </GiftRowBase>
</template>

<script>
import GiftRowBase from './RowBase'

export default {
  name: 'GiftRowCash',
  props: ['gift'],
  components: {
    GiftRowBase
  },
  computed: {
    description() {
      return '£' + this.gift.amount
    }
  }
}
</script>
