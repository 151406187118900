<template>
  <tr class="app-table-row">
    <td class="gift-description">
      <img alt="add cash gift" :src="img" />
      <h5 class="inline-block align-middle">
        {{ description }}

        <span v-if="shared"> ({{ share(shared) }}) </span>

        <span
          v-if="!charityProtected"
          v-on:click="$emit('editGift')"
          class="gift-del"
          title="Edit this item"
        >
          <i class="fa fa-pen"></i>
        </span>
      </h5>
    </td>
    <td class="gift-beneficiary">
      <h5 class="inline-block align-middle">
        {{ beneficiary }}
        <span
          v-if="!charityProtected"
          v-on:click="$emit('deleteGift')"
          class="gift-del"
          title="Remove this item"
        >
          <i class="fa fa-trash-alt"></i>
        </span>
      </h5>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'GiftRowBase',
  props: ['img', 'description', 'beneficiary', 'shared', 'charityProtected'],
  methods: {
    share(value) {
      if (value === 'shared') return 'shared'
      else if (value === 'each') return 'each'
    }
  }
}
</script>
