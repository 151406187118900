<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="app-table fadeIn-5">
        <table class="gifts-table">
          <thead class="gifts-wrap-title-main">
            <tr>
              <th class="gift-description">Description</th>
              <th class="gift-beneficiary">Beneficiary</th>
            </tr>
          </thead>
          <tbody>
            <GiftRowCash
              v-for="(gift, index) in cash"
              :key="gift.amount + index"
              :gift="gift"
              @editCashGift="$emit('editCashGift', gift)"
              @deleteCashGift="$emit('deleteCashGift', gift)"
            >
            </GiftRowCash>
            <GiftRowVehicle
              v-for="(gift, index) in vehicle"
              :key="gift.model + index"
              :gift="gift"
              @editVehicleGift="$emit('editVehicleGift', gift)"
              @deleteVehicleGift="$emit('deleteVehicleGift', gift)"
            >
            </GiftRowVehicle>
            <GiftRowOther
              v-for="(gift, index) in other"
              :key="gift.item + index"
              :gift="gift"
              @editOtherGift="$emit('editOtherGift', gift)"
              @deleteOtherGift="$emit('deleteOtherGift', gift)"
            >
            </GiftRowOther>
            <GiftRowCharity
              v-for="(gift, index) in charity"
              :key="gift.charity_name + index"
              :gift="gift"
              @editCharityGift="$emit('editCharityGift', gift)"
              @deleteCharityGift="$emit('deleteCharityGift', gift)"
            >
            </GiftRowCharity>
            <GiftRowProperty
              v-for="(gift, index) in property"
              :key="gift.post_code + index"
              :gift="gift"
              @editPropertyGift="$emit('editPropertyGift', gift)"
              @deletePropertyGift="$emit('deletePropertyGift', gift)"
            >
            </GiftRowProperty>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import GiftRowCash from '../Table/RowCash'
import GiftRowCharity from '../Table/RowCharity'
import GiftRowOther from '../Table/RowOther'
import GiftRowProperty from '../Table/RowProperty'
import GiftRowVehicle from '../Table/RowVehicle'

export default {
  name: 'GiftTable',
  props: ['cash', 'property', 'other', 'charity', 'vehicle'],
  components: {
    GiftRowCash,
    GiftRowCharity,
    GiftRowOther,
    GiftRowProperty,
    GiftRowVehicle
  },
  methods: {}
}
</script>
