<template>
  <div class="input-container">
    <v-select
      v-model="selected"
      :options="charities"
      @search="fetchCharities"
      @input="emitSelected"
      placeholder="Search charities ..."
      :class="colour"
    ></v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
  name: 'CharitiesSelect',
  components: {
    vSelect
  },
  props: ['colour'],
  watch: {
    selected(value) {
      this.$emit('selectedCharity', value)
    }
  },
  data() {
    return {
      charities: [],
      selected: ''
    }
  },
  methods: {
    fetchCharities(search, loading) {
      if (search) {
        http
          .get('/wills/api/charity_list_lookup/' + search)
          .then((response) => {
            this.charities = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.chairities = []
      }
    },
    emitSelected(value) {
      this.$emit('selected', value)
    }
  }
}
</script>

<style>
.vs--single .vs__selected {
  background-color: #4f9b7e;
}
.grey .vs__dropdown-toggle {
  background: #f9f9f9;
  border-bottom-color: #e56781;
  border-bottom-width: 3px;
}
</style>
