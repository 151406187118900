<template>
  <div>
    <BaseModalSmall
      @close="close"
      v-show="show"
      :show="show"
      v-bind:isValidated="validateForm"
      v-bind:loading="showProgress"
      @confirm="save"
    >
      <template v-slot:header>{{ title }}</template>
      <template v-slot:body>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <AddressSearch @addressSelected="setAddress" :colour="'grey'">
            </AddressSearch>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-container">
              <input
                v-model="data.address_line_1"
                type="text"
                class="input-standard-grey validation"
                placeholder="Address Line 1"
                required
                id="address_line_1"
              />
              <label for="address_line_1">Address Line 1</label>
              <ErrorPop
                v-show="errors.address_line_1"
                :message="errors.address_line_1"
                :displayClose="true"
                @clearError="errors.address_line_1 = null"
              ></ErrorPop>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-container">
              <input
                v-model="data.post_town"
                type="text"
                class="input-standard-grey validation"
                placeholder="Post Town"
                required
              />
              <label>Post Town</label>
              <ErrorPop
                v-show="errors.post_town"
                :message="errors.post_town"
                :displayClose="true"
                @clearError="errors.post_town = null"
              ></ErrorPop>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-container">
              <input
                v-model="data.post_code"
                type="text"
                class="input-standard-grey validation"
                placeholder="Post Code"
                required
              />
              <label>Post Code</label>
              <ErrorPop
                v-show="errors.post_code"
                :message="errors.post_code"
                :displayClose="true"
                @clearError="errors.post_code = null"
              ></ErrorPop>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb30">
            <div class="sole-joint">
              <p>Who owns this property?</p>
              <InputSwitch
                :checked="data.joint_owned"
                @toggle="jointOwnedToggle"
              ></InputSwitch>
            </div>
          </div>

          <BeneficiaryAccordian
            :description="'Who is to be the recipient of the gift?'"
            v-bind:name="data.full_name"
            v-on:name="data.full_name = $event"
            v-bind:gender="data.gender"
            v-on:gender="data.gender = $event"
            v-bind:email="data.email"
            v-on:email="data.email = $event"
            v-bind:address="data.address"
            v-on:address="data.address = $event"
            v-bind:phone="data.phone"
            v-on:phone="data.phone = $event"
            v-bind:relationship="data.relationship"
            v-on:relationship="data.relationship = $event"
            v-bind:group="data.beneficiary_group"
            v-on:group="data.beneficiary_group = $event"
            v-bind:person="data.beneficiary"
            v-on:person="data.beneficiary = $event"
            v-bind:manualRelationship="manualRelationship"
            v-on:manualRelationship="manualRelationship = $event"
            :close="show"
            :errors="errors"
            :edit="edit"
            :shareNotRequired="true"
            :gift="true"
            @showExisting="clearGroup(), clearNew()"
            @showNew="clearExisting(), clearGroup()"
            @showGroup="clearExisting(), clearNew()"
            @clearBeneficiaryError="errors.beneficiary = null"
            @clearFullNameError="errors.full_name = null"
            @clearEmailError="errors.email = null"
            @clearAddressError="errors.address = null"
            @clearPhoneError="errors.phone = null"
          >
          </BeneficiaryAccordian>
        </div>
      </template>
      <template v-slot:button>
        {{ buttonText }}
      </template>
    </BaseModalSmall>

    <BaseModalSmall
      @close="closeTerms"
      v-show="showTerms"
      v-bind:isValidated="true"
      @confirm="acceptTerms"
      :disableBackground="false"
      :noSlide="true"
    >
      <template v-slot:header>Warning!</template>
      <template v-slot:body>
        <div v-html="termsDescription"></div>
      </template>
      <template v-slot:button> Accept </template>
    </BaseModalSmall>
  </div>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import BeneficiaryAccordian from './BeneficiaryAccordian'
import InputSwitch from '@/common/ui/InputSwitch'
import ErrorPop from '@/common/ui/ErrorPop'
import AddressSearch from '@/common/components/AddressSearch'

import { http } from '@/services'
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'GiftPropertyModal',
  mixins: [TextFormat],
  components: {
    BaseModalSmall,
    BeneficiaryAccordian,
    InputSwitch,
    ErrorPop,
    AddressSearch
  },
  props: ['show', 'edit', 'before', 'after'],
  watch: {
    edit: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.id = value.id
          this.data.address_line_1 = value.address_line_1
          this.data.post_code = value.post_code
          this.data.post_town = value.post_town
          this.data.joint_owned = value.joint_owned
          if (value.beneficiary_group)
            this.data.beneficiary_group = value.beneficiary_group
          if (value.beneficiary) this.data.beneficiary = value.beneficiary.id
        } else {
          this.clearAll()
        }
      }
    },
    before: {
      immediate: true,
      handler(value) {
        if (value) this.data.gift_before_partner = true
      }
    },
    after: {
      immediate: true,
      handler(value) {
        if (value) this.data.gift_after_partner = true
      }
    }
  },
  computed: {
    validateForm() {
      if (
        this.data.address_line_1 &&
        this.data.post_code &&
        this.data.post_town
      ) {
        if (this.data.beneficiary) return true
        if (this.data.full_name && this.data.gender && this.data.relationship) {
          if (
            this.data.relationship === 'friend' &&
            (this.data.phone || this.data.address || this.data.email)
          )
            return true
          else if (
            this.data.relationship === 'other' &&
            this.manualRelationship
          )
            return true
          else if (
            this.data.relationship !== 'friend' &&
            this.data.relationship !== 'other'
          )
            return true
        }
        if (this.data.beneficiary_group) return true
      }
      return false
    },
    title() {
      if (this.edit) return 'Update Property Gift'
      return 'Add Property Gift'
    },
    buttonText() {
      if (this.edit) return 'Update Gift'
      return 'Add Gift'
    },
    partner() {
      return this.$store.getters.partner
    },
    termsDescription() {
      if (this.$store.getters.personalDetails.country === 'Scotland') {
        if (this.after && this.partner) {
          return (
            '<p>If this property is owned in joint names with ' +
            this.firstWord(this.partner.full_name) +
            ' it will pass to the recipient once you have both passed away. However if the property is owned in ' +
            'joint names with anybody else the gift will fail unless you hold the property in pro indivisio ' +
            'shares or unless the other owner(s) have passed away before yourself. Instead it will pass to ' +
            'the surviving joint owner(s)). <br><br>For more information about this please email info@mylastwill.co.uk.</p>'
          )
        } else {
          return (
            '<p>Where a property is owned in joint names the gift will fail unless you hold the property in pro ' +
            'indivisio shares (it will not pass to the intended recipient, instead it will pass to the surviving ' +
            'joint owner(s)).<br><br> For more information about this please email info@mylastwill.co.uk.</p>'
          )
        }
      } else {
        if (this.after && this.partner) {
          return (
            '<p>If this property is owned in joint names with ' +
            this.firstWord(this.partner.full_name) +
            ' it will pass to the recipient once you have both passed away. However if the property is owned in ' +
            'joint names with anybody else the gift will fail unless you hold the property as tenants in common ' +
            'or unless the other owner(s) have passed away before yourself. Instead it will pass to the surviving ' +
            'joint owner(s)).<br><br> For more information about this please email info@mylastwill.co.uk.</p>'
          )
        } else {
          return (
            '<p>Where a property is owned in joint names the gift will fail unless you hold the property as tenants' +
            ' in common (it will not pass to the intended recipient, instead it will pass to the surviving joint ' +
            'owner(s)).<br><br> For more information about this please email info@mylastwill.co.uk.</p>'
          )
        }
      }
    }
  },
  data() {
    return {
      data: {
        id: null,
        address_line_1: null,
        post_town: null,
        post_code: null,
        beneficiary: null,
        full_name: null,
        address: null,
        email: null,
        phone: null,
        gender: null,
        relationship: '',
        beneficiary_group: '',
        gift_before_partner: false,
        gift_after_partner: false,
        joint_owned: false
      },
      errors: {
        address_line_1: '',
        post_town: '',
        post_code: '',
        beneficiary: '',
        full_name: '',
        address: '',
        email: '',
        phone: ''
      },
      showTerms: false,
      showProgress: false,
      manualRelationship: ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.clearAll()
    },
    save(value) {
      if (this.edit && this.data.id) {
        this.patch()
      } else {
        this.post()
      }
    },
    clearErrors() {
      this.errors.address_line_1 = ''
      this.errors.post_code = ''
      this.errors.post_town = ''
      this.errors.full_name = ''
      this.errors.address = ''
      this.errors.phone = ''
      this.errors.email = ''
      this.errors.beneficiary = ''
    },
    clearAll() {
      this.data.id = null
      this.data.address_line_1 = null
      this.data.post_code = null
      this.data.post_town = null
      this.data.joint_owned = false
      this.clearExisting()
      this.clearNew()
      this.clearGroup()
    },
    clearExisting() {
      this.data.beneficiary = null
    },
    clearNew() {
      this.data.full_name = null
      this.data.address = null
      this.data.email = null
      this.data.phone = null
      this.data.gender = null
      this.data.relationship = ''
    },
    clearGroup() {
      this.data.beneficiary_group = ''
    },
    jointOwnedToggle() {
      if (this.data.joint_owned) {
        this.data.joint_owned = false
      } else {
        this.data.joint_owned = true
        this.showTerms = true
      }
    },
    acceptTerms() {
      this.data.joint_owned = true
      this.showTerms = false
    },
    closeTerms() {
      this.showTerms = false
      this.data.joint_owned = false
    },
    post() {
      this.clearErrors()
      if (this.data.relationship === 'other' && this.manualRelationship)
        this.data.relationship = this.manualRelationship
      http
        .post('/wills/api/gift_real_estate', this.data)
        .then((response) => {
          this.$store.commit('giftPropertyAdd', response.data)
          if (this.data.full_name) this.$store.dispatch('peopleFetch')
          this.close()
          this.$notification.success('Property gift created')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    patch() {
      this.clearErrors()
      http
        .patch('/wills/api/gift_real_estate', this.data)
        .then((response) => {
          this.$store.commit('giftPropertyAdd', response.data)
          if (this.data.full_name) this.$store.dispatch('peopleFetch')
          this.close()
          this.$notification.success('Property gift edited')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    errorHandler(e) {
      if (e.response.data.address_line_1) {
        this.errors.address_line_1 = e.response.data.address_line_1[0]
      }
      if (e.response.data.post_code) {
        this.errors.post_code = e.response.data.post_code[0]
      }
      if (e.response.data.post_town) {
        this.errors.post_town = e.response.data.post_town[0]
      }
      if (e.response.data.beneficiary) {
        this.errors.beneficiary = e.response.data.beneficiary[0]
      }
      if (e.response.data.full_name) {
        this.errors.full_name = e.response.data.full_name[0]
      }
      if (e.response.data.phone) {
        this.errors.phone = e.reponse.data.phone[0]
      }
      if (e.response.data.email) {
        this.errors.email = e.reponse.data.email[0]
      }
      if (e.response.data.address) {
        this.errors.address = e.reponse.data.address[0]
      }
    },
    setAddress(value) {
      if (value) {
        this.data.address_line_1 = value.line_1 + ', ' + value.line_2
        this.data.post_town = value.post_town
        this.data.post_code = value.postcode
      }
    }
  }
}
</script>
