<template>
  <BaseModalSmall
    @close="close"
    v-show="show"
    :show="show"
    v-bind:isValidated="validateForm"
    v-bind:loading="showProgress"
    @confirm="save"
  >
    <template v-slot:header>{{ title }}</template>
    <template v-slot:body>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.amount"
              type="number"
              class="input-standard-grey validation"
              placeholder="Amount"
              required
              id="amount"
            />
            <label for="amount">Amount</label>
            <ErrorPop
              v-show="errors.amount"
              :message="errors.amount"
              :displayClose="true"
              @clearError="errors.amount = null"
            ></ErrorPop>
            <span class="input-icon">£</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="control">
            <label class="switch inflation mb30 f-right">
              <input
                v-on:click="data.rpi = !data.rpi"
                type="checkbox"
                class="switch"
                :checked="data.rpi"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb30">
          <CharitiesSelect @selected="setCharity" :colour="'grey'" />
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.charity_name"
              type="text"
              class="input-standard-grey validation"
              placeholder="Charity Name"
              required
              id="name"
            />
            <label for="amount">Charity Name</label>
            <ErrorPop
              v-show="errors.charity_name"
              :message="errors.charity_name"
              :displayClose="true"
              @clearError="errors.charity_name = null"
            ></ErrorPop>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.charity_number"
              type="text"
              class="input-standard-grey validation"
              placeholder="Charity Number"
              required
              id="number"
            />
            <label for="amount">Charity Number</label>
            <ErrorPop
              v-show="errors.charity_number"
              :message="errors.charity_number"
              :displayClose="true"
              @clearError="errors.charity_number = null"
            ></ErrorPop>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:button>
      {{ buttonText }}
    </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import ErrorPop from '@/common/ui/ErrorPop'
import CharitiesSelect from '../../../../../common/components/CharitiesSelect'
import { http } from '@/services'

export default {
  name: 'GiftCharityModal',
  components: {
    BaseModalSmall,
    ErrorPop,
    CharitiesSelect
  },
  props: ['show', 'edit', 'before', 'after'],
  watch: {
    edit: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.id = value.id
          this.data.amount = value.amount
          this.data.rpi = value.rpi
          this.data.charity_name = value.charity_name
          this.data.charity_number = value.charity_number
        }
      }
    },
    before: {
      immediate: true,
      handler(value) {
        if (value) this.data.gift_before_partner = true
      }
    },
    after: {
      immediate: true,
      handler(value) {
        if (value) this.data.gift_after_partner = true
      }
    }
  },
  computed: {
    validateForm() {
      if (
        this.data.amount &&
        this.data.charity_name &&
        this.data.charity_number
      )
        return true
      return false
    },
    title() {
      if (this.edit) return 'Update Charity Gift'
      return 'Add Charity Gift'
    },
    buttonText() {
      if (this.edit) return 'Update Gift'
      return 'Add Gift'
    }
  },
  data() {
    return {
      data: {
        id: null,
        amount: null,
        rpi: true,
        beneficiary: '',
        charity_name: '',
        charity_number: '',
        full_name: null,
        address: null,
        email: null,
        phone: null,
        gender: null,
        relationship: '',
        beneficiary_group_share: null,
        beneficiary_group: '',
        gift_before_partner: false,
        gift_after_partner: false
      },
      errors: {
        amount: '',
        charity_number: '',
        charity_name: '',
        beneficiary: '',
        full_name: '',
        address: '',
        email: '',
        phone: ''
      },
      showProgress: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.clearAll()
    },
    save(value) {
      if (this.edit && this.data.id) {
        this.patch()
      } else {
        this.post()
      }
    },
    clearErrors() {
      this.errors.amount = ''
      this.errors.charity_number = ''
      this.errors.charity_name = ''
      this.errors.full_name = ''
      this.errors.address = ''
      this.errors.phone = ''
      this.errors.email = ''
      this.errors.beneficiary = ''
    },
    clearAll() {
      this.data.id = null
      this.data.amount = null
      this.data.rpi = true
      this.data.charity_name = null
      this.data.charity_number = null
    },
    post() {
      this.clearErrors()
      http
        .post('/wills/api/gift_charity', this.data)
        .then((response) => {
          this.$store.commit('giftCharityAdd', response.data)
          if (this.data.full_name) this.$store.dispatch('peopleFetch')
          this.close()
          this.$notification.success('Charity gift created')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    patch() {
      this.clearErrors()
      http
        .patch('/wills/api/gift_charity', this.data)
        .then((response) => {
          this.$store.commit('giftCharityAdd', response.data)
          this.close()
          this.$notification.success('Charity gift edited')
        })
        .catch((error) => {
          this.errorHandler(error)
        })
    },
    errorHandler(e) {
      if (e.response.data.amount) {
        this.errors.amount = e.response.data.amount[0]
      }
      if (e.response.data.charity_number) {
        this.errors.charity_number = e.response.data.charity_number[0]
      }
      if (e.response.data.charity_name) {
        this.errors.charity_name = e.response.data.charity_name[0]
      }
    },
    setCharity(value) {
      if (value) {
        this.data.charity_name = value.name
        this.data.charity_number = value.number
      } else {
        this.clearCharity()
      }
    },
    clearCharity() {
      this.data.charity_name = null
      this.data.charity_number = null
    }
  }
}
</script>
