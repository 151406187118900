<template>
  <div class="input-container">
    <select
      v-model="id"
      v-on:change="$emit('selected', id)"
      class="nice-select"
      :required="required"
      :class="{ validation: required }"
      id="person"
    >
      <option :value="null" selected>Select person</option>
      <option
        v-for="(value, index) in options"
        v-bind:key="index"
        v-bind:value="value.id"
      >
        {{ capitalise(value.full_name) }}
      </option>
      <label for="person">Person</label>
    </select>
  </div>
</template>

<script>
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'PersonSelect',
  mixins: [TextFormat],
  props: ['psn', 'required', 'distributionPrimary', 'peopleOptionList'],
  watch: {
    psn: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) this.id = newVal
        else this.id = null
      }
    }
  },
  data() {
    return {
      id: null
    }
  },
  computed: {
    options() {
      if (this.peopleOptionList) return this.peopleOptionList
      if (this.distributionPrimary)
        return this.$store.getters.people.filter(
          (person) => !person.distribution_primary
        )
      return this.$store.getters.people
    }
  }
}
</script>
