<template>
    <div class="input-container">
        <select
                v-model="group"
                v-on:change="$emit('selected', group)"
                class="nice-select"
                :required="required"
                :class="{ validation: required }"
                id="group"
        >
            <option value selected disabled>Select Group</option>
            <option
                    v-for="(value, index) in options"
                    v-bind:key="index"
                    v-bind:value="value"
            >
                {{ capitalise(value) }}
            </option>
            <label for="group">Group</label>
        </select>
    </div>
</template>

<script>
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'GroupSelect',
  mixins: [TextFormat],
  props: ['grp', 'required'],
  watch: {
    grp: {
      immediate: true,
      handler (newVal, oldVal) {
        this.group = newVal
      }
    }
  },
  data () {
    return {
      group: '',
      baseOptions: [
        'children',
        'daughters',
        'sons',
        'grandchildren',
        'granddaughters',
        'grandsons',
        'brothers',
        'sisters',
        'brothers and sisters',
        'nephews',
        'nephews and nieces',
        'nieces',
        'parents',
        'cousins',
      ],

      additionalOptions: [
        'children',
        'daughters',
        'sons',
        'grandchildren',
        'granddaughters',
        'grandsons',
        'brothers',
        'sisters',
        'brothers and sisters',
        'nephews',
        'nephews and nieces',
        'nieces',
        'parents',
        'cousins',
        'children and my children'
      ]
    }
  },
  computed: {
    options () {
      var options = []
      this.baseOptions.forEach((el) => options.push(el))
      if (this.partnerRelationship) {
        var prefix = this.partnerRelationship + '\'s '
        this.additionalOptions.forEach((el) => options.push(prefix + ' ' + el))
      }
      return options
    },
    partnerRelationship () {
      if (this.$store.getters.partner) {
        return this.$store.getters.partner.relationship
      } else {
        return null
      }
    }
  },
  methods: {}
}
</script>
