var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row input-option-wrapper"},[_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('createCashGift')}}},[_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('createPropertyGift')}}},[_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('createVehicleGift')}}},[_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('createCharityGift')}}},[_vm._m(6),_vm._m(7)])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding"},[_c('div',{staticClass:"gift-option",on:{"click":function($event){return _vm.$emit('createOtherGift')}}},[_vm._m(8),_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add cash gift","src":"https://mylastwill.s3.amazonaws.com/static/img/button-gift-cash.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("cash gift")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add property gift","src":"https://mylastwill.s3.amazonaws.com/static/img/button-gift-property.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("property gift")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add vehicle gift","src":"https://mylastwill.s3.amazonaws.com/static/img/button-gift-car.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("vehicle gift")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add charity gift","src":"https://mylastwill.s3.amazonaws.com/static/img/button-gift-charity.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("charity gift")]),_c('i',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"alt":"add other gift","src":"https://mylastwill.s3.amazonaws.com/static/img/button-gift-item.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("other gift")]),_c('i',{staticClass:"fa fa-plus"})])
}]

export { render, staticRenderFns }