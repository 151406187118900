<template>
  <GiftRowBase
    :img="'https://mylastwill.s3.amazonaws.com/static/img/button-gift-charity.png'"
    :description="description"
    :beneficiary="gift.charity_name"
    :charityProtected="gift.charity_protected"
    @editGift="$emit('editCharityGift')"
    @deleteGift="$emit('deleteCharityGift')"
  >
  </GiftRowBase>
</template>

<script>
import GiftRowBase from './RowBase'

export default {
  name: 'GiftRowCharity',
  props: ['gift'],
  components: {
    GiftRowBase
  },
  computed: {
    description() {
      return '£' + this.gift.amount
    }
  }
}
</script>
