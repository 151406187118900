import { render, staticRenderFns } from "./CharitiesSelect.vue?vue&type=template&id=0f7b4224"
import script from "./CharitiesSelect.vue?vue&type=script&lang=js"
export * from "./CharitiesSelect.vue?vue&type=script&lang=js"
import style0 from "./CharitiesSelect.vue?vue&type=style&index=0&id=0f7b4224&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports