<template>
  <GiftRowBase
    :img="'https://mylastwill.s3.amazonaws.com/static/img/button-gift-car.png'"
    :description="description"
    :shared="gift.beneficiary_group_share"
    :beneficiary="gift.beneficiary_group || gift.beneficiary.full_name"
    @editGift="$emit('editVehicleGift')"
    @deleteGift="$emit('deleteVehicleGift')"
  >
  </GiftRowBase>
</template>

<script>
import GiftRowBase from './RowBase'

export default {
  name: 'GiftRowVehicle',
  props: ['gift'],
  components: {
    GiftRowBase
  },
  computed: {
    description() {
      return this.gift.make + ', ' + this.gift.model
    }
  }
}
</script>
