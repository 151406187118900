<template>
  <div class="input-container">
    <v-select
      v-model="selected"
      @search="fetchAddressList"
      @input="getSelected"
      :options="options"
      placeholder="Address Search... "
      :class="colour"
    ></v-select>
  </div>
</template>

<script>
import { http } from '@/services'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'AddressSearch',
  props: ['colour'],
  components: {
    vSelect
  },
  data() {
    return {
      postCodeSearch: null,
      selected: null,
      options: [],
      input: null,
      apiKey: 'ak_jgmk4levJtTl4GYQLZcfzG9wKyi3m'
    }
  },
  watch: {
    postCodeSearch(value) {
      if (value) this.fetchAddressList(value)
    }
  },
  methods: {
    fetchAddressList(query) {
      if (query.length >= 3) {
        var address = `https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=${this.apiKey}&query=${query}`
        http
          .get(address)
          .then((response) => {
            this.options = response.data.result.hits.map((value) => {
              return {
                label:
                  value.suggestion.slice(0, value.suggestion.length - 4) +
                  query,
                value: value
              }
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    getSelected(value) {
      if (value) {
        var address = `https://api.ideal-postcodes.co.uk/${value.value.urls.udprn}?api_key=${this.apiKey}`
        http
          .get(address)
          .then((response) => {
            if (response.data.message === 'Success') {
              this.$emit('addressSelected', response.data.result)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style>
.vs--single .vs__selected {
  background-color: #4f9b7e;
}
.grey .vs__dropdown-toggle {
  background: #f9f9f9;
  border-bottom-color: #e56781;
  border-bottom-width: 3px;
}
</style>
