<template>
  <div class="row input-option-wrapper">
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('createCashGift')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add cash gift"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-gift-cash.png"
          />
        </div>
        <div class="text">
          <p>cash gift</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('createPropertyGift')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add property gift"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-gift-property.png"
          />
        </div>
        <div class="text">
          <p>property gift</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('createVehicleGift')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add vehicle gift"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-gift-car.png"
          />
        </div>
        <div class="text">
          <p>vehicle gift</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('createCharityGift')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add charity gift"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-gift-charity.png"
          />
        </div>
        <div class="text">
          <p>charity gift</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-4 option-padding">
      <div v-on:click="$emit('createOtherGift')" class="gift-option">
        <div class="image-wrapper">
          <img
            alt="add other gift"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-gift-item.png"
          />
        </div>
        <div class="text">
          <p>other gift</p>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GiftInputOptions',
  props: []
}
</script>
